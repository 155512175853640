<template>
    <div class="card">
        <div class="left tab-bar">
            <div class="clickable tab" @click="active = '农业气象月报'" :class="active === '农业气象月报' ? 'active' : ''">
                农业气象月报
            </div>
            <div class="clickable tab" @click="active = '土壤水分监测报告'" :class="active === '土壤水分监测报告' ? 'active' : ''">
                土壤水分监测报告
            </div>

            <div class="extra" @click="$router.push(`/article-category?name=农业气象`)">
                <i class="iconfont icon-arrow-right"></i>
            </div>
        </div>
        <div class="body" @click="$router.push(`/article-category?name=农业气象`)">
            <img class="image" :src="monthImage" alt="" v-if="active === '农业气象月报'">
            <img class="image" :src="month2Image" alt="" v-else>
        </div>
        <!-- {{JSON.stringify(path)}} -->
    </div>

</template>

<script>
import monthImage from '@/assets/farm/month.jpg'
import month2Image from '@/assets/farm/month2.jpg'
export default {
    
    props: {
        detail: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            active: '农业气象月报',
            monthImage,
            month2Image
        }
    },
    computed: {
        path() {
        if (this.active === '农业气象月报') {
            return this.detail.nyqxyb;
        } else {
            return this.detail.trsfjcbg
        }
        }

        
        
    }
}
</script>

<style lang="less" scoped>




.card {
    background-color: #fff;
            grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        padding: 0 12px 12px;

    .tab-bar {
        margin-bottom: 12px;
        .tab {
            padding: 12px 24px;
            text-align: center;
        }
        .active {
            color: var(--theme-color);
            font-weight: bold;
            border-bottom: 2px solid var(--theme-color);
        }
        .extra {
            margin-left: auto;
            cursor: pointer;
        }
    }

    .body {
        cursor: pointer;
            .image {
                    width: 100%;
                    height: 480px;
                    object-fit: cover;
                    
                }
    }

}
</style>