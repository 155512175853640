<template>
    <div class="container">
        <div class="panel">
            <Info :detail="detail" />
            <Card title="农业气象灾害监测与预警" url="/article-category?name=农业气象">
                <List :icon="icon1" :list="detail.nyqxzhjcyyj" />
            </Card>
            <Card title="特色农业气象服务" url="/article-category?name=农业气象">
                <List :icon="icon2" :list="detail.tsnyqxfw" />
            </Card>
            <Card title="设施农业气象服务" url="/article-category?name=农业气象">
                <List :icon="icon3" :list="detail.ssnyqxfw" />
            </Card>
            <Card title="农业病虫害发生发展等级预报" url="/article-category?name=农业气象">
                <List :icon="icon4" :list="detail.nybchfsfzdjyb" />
            </Card>
            <Card title="关键农事气象服务" url="/article-category?name=农业气象">
                <List :icon="icon5" :list="detail.gjnsqxfw" />
            </Card>
        </div>
    </div>
</template>

<script>
import Info from './components/info.vue'
import List from './components/list'
import { fetchList } from '@/api/article'

export default {
    components: {
        Info,
        List,
    },

    data() {
        return {
                    icon1: require('@/assets/farm/farm1.gif'),
            icon2: require('@/assets/farm/farm2.gif'),
            icon3: require('@/assets/farm/farm3.gif'),
            icon4: require('@/assets/farm/farm4.gif'),
            icon5: require('@/assets/farm/farm5.gif'),
            detail: {}
        }
    },

    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            fetchList({
                p: [{
                    category: "nyqxyb",
                    page: 1,
                    rows: 3,
                },{
                             category: "trsfjcbg",
                    page: 1,
                    rows: 3,
                },{
                             category: "nyqxzhjcyyj",
                    page: 1,
                    rows: 3,
                },{
                             category: "tsnyqxfw",
                    page: 1,
                    rows: 3,
                },{
                             category: "ssnyqxfw",
                    page: 1,
                    rows: 3,
                },{
                             category: "nybchfsfzdjyb",
                    page: 1,
                    rows: 3,
                },{
                             category: "gjnsqxfw",
                    page: 1,
                    rows: 3,
                }
                ]
            }).then(res => {
                if (Array.isArray(res) && res.length > 0) {
                    console.log(res)
                    const obj = {}
                    res.forEach(item => {
     if (item.data && Array.isArray(item.data.list)) {
                            obj[item.category] = item.data.list;
                        }
                    }
                   
                    )
                    this.detail = obj;

                }
            })
        }
    }

}
</script>

<style lang="less" scoped>
  
</style>