<template>
    <div>
        <div class="list">
            <img :src="icon" alt="">
            <a v-for="item in list" :key="item.code" href="#" class="item"  @click.prevent="openArticleDetail(item)"
                :title="item.title">
                    {{item.title}}
            </a>
        </div>
    </div>
</template>

<script>
import article from '@/mixins/article'
export default {
    mixins: [article],
    props: {
        icon: {
            type: String
        },
        list: {
            type: Array,
            default: () => []
        }
    },
}
</script>

<style lang="less" scoped>

.list {
    width: 300px;
    display: flex;
    flex-direction: column;
    img {
        width: 100%;
        margin-bottom: 8px;
    }

    .item {
        color: #888;
        font-size: 12px;
        line-height: 2em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>